@import url('https://fonts.cdnfonts.com/css/varela-round-3');


body {
    margin: 0;
     font-family: Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
    color: #fdf061;
}

.navbar-brand img {
    width: 150px;
    margin-right: 10px;
}

.btn {
    font-size: 20px;
}

.btn-primary,
.bg-primary {
    background-color: #00bcee !important;
    border: none;
}

.btn-primary:hover {
    background-color: #f6b26d;
    opacity: 0.9;
}

.btn-secondary {
    background-color: #fdf061;
}

.btn-secondary:hover {
    background-color: #fdf061;
    opacity: 0.9;
}

.icon {
    margin-right: 5px;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar ul {
    display: flex;
}

.navbar ul li {
    margin-left: 20px;
}

.header {
    background-color: #fffffe29;
    color: #2e2927;
    min-height: 200px;
}

.header h3 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
}


.header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.boxes .container {
    display: flex;
    justify-content: space-between;
}

.box {
    flex: 1;
    background: #f6b26d;
    color: #2e2927;
    border-radius: 10px;
    margin: 20px 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
    padding: 15px 20px;
}

.box i {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .header .container {
        flex-direction: column;
        padding-top: 20px;
        text-align: center;
    }

    .boxes .container {
        display: block;
        text-align: center;
    }
}

main > .container {
    padding: 60px 15px 0;
}

.header-title {
    background: linear-gradient(90deg, rgba(253, 126, 20, 1) 50%, rgba(109, 57, 37, 1) 100%, rgb(253, 126, 20) 100%);
    color: #ffffff;
}
