.header-holder {
  display: flex;
  flex-flow: column wrap;
  // background-color: #ff954e;
  background-color: #ffff;



  .top-header-links {    // top border 
    min-height: 25px;
    // background: #ff954e;
    background: #fff;
    width: 100%;
    display: flex;
    flex-flow: wrap row;
    align-items: center;
   
   // box-shadow: 0px 4px 8px #214980;
    z-index: 11;
  }

  .header-content {
    z-index: 9;
    min-height: 110px;
    width: 100%;
    max-width: 100% !important;
    background: var(--primary-color);
    color: rgb(6, 6, 6);
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);



    .imgmove-right {
      /*border: solid 1px red;*/
      overflow: hidden;
      height: 95px;
      width: 100px;
      float: left;
      padding: 5px 0px 0px 20px  !important;
    }
  
  .imgmove-left{
      /*border: solid 1px red;*/
      overflow: hidden;
      height: 95px;
      width: 100px;
      float: right;
      //margin-bottom: -120% !important;
      padding: 5px 20px 0px 0px  !important;
  
  }

    // .emblem,
    // .logo {
    //   min-height: 100px;
    //   align-items: center;
    //   padding-top: 8px;

      // span {
      //   display: flex;
      //   width: 100%;
      //   justify-content: center;
      //   opacity: 0.85;

      //   &.emblem-img-holder {
      //     border-right: 1px solid #a3178c;
      //   }

      //   &.logo-img-holder {
      //     border-left: 1px solid #79a317;
      //   }
      // }

      img {
        height: 80px;
        width: auto;
      }
    //}

   .jamuhuri {   // jamuhuri ya muungano
      font-weight: 700;

      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
       margin: 10px -220px 3px 45px;

      font-weight: 900;
      color: #333;


    }
    .tume { // tume ya uchaguzi
      font-weight: 700;

      font-family: 'Open Sans', sans-serif;
      font-size: 35px;
       margin: 34px 25px 3px -145px;

      font-weight: bold;
      color: #333;


    }


    .nec-header-text {  //content of logo
      flex-direction: column;
      align-items: center;

     margin-left: 30%;
      padding-top: 12px;
      

      > div:nth-child(1) {   // jamuhuri ya muungano
        font-weight: 700;

        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
         margin: 0px -25px 3px 45px;

        font-weight: 900;
        color: #333;


      }


      > div:nth-child(3) { // tume ya uchaguzi
        font-weight: 700;

        font-family: 'Open Sans', sans-serif;
        font-size: 35px;
         margin: 4px 25px 3px -25px;

        font-weight: bold;
        color: #333;


      }

      > div:nth-child(2) {
        font-size: 20px;
        font-weight: 800;
        word-spacing: 4px;
        letter-spacing: 1px;
      }

      > div:nth-child(1),
      > div:nth-child(3) {
        
        color: black;
        font-weight: 700;

      }
    }

    .bottom-line {
      height: 30px;
      width: 100%;
      margin: auto;
      background: #ff954e;
;
      margin-top: 20px;
     // background: linear-gradient(to left, #1353aa, #327bde);
    }

    .public-dashboard {
      min-height: 35px;
      color: #ffd500;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 18px;
      word-spacing: 8px;
      letter-spacing: 1px;

      span {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .statistics-text {
     min-height: 0px; // height from menu to body
    background-color: #434343;
    // background-image: url('/assets/images/artwork.png');
    //background-image: url('/assets/images/fg.png');
    background-repeat: no-repeat;
    //background-size: 80%;
    transform: scale(1.0);

    //background-position: 0px -175px;
    background-position: 0px -195px;
     background-size: cover;
    position: relative;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: var(--primary-color);
      opacity: 0.93;
      display: block;
    }
    .online-vrs{
      font-size: 148px;
      font-weight: 900;

    }
    .stat-data {
      position: absolute;
      top: 5px;
      left: 0px;
      color: white;
      z-index: 8;
      display: flex;
      flex-flow: wrap column;
      align-items: center;
      justify-content: center;
      width: 100%;

      > div:first-child {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 15px;
      }

      > div:last-child {
        font-size: 14px;

        span {
          display: inline-block;
          border-right: 1px solid #1752a3;
          padding-right: 10px;
          padding-left: 10px;
          color: #96c3ff;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .navigation-list {
    position: absolute;
    top: 115px;
    left: 0px;
    z-index: 8;
    width: 88%;
    margin-left: 6%;
    display: flex;
    padding: 10px;
    background: rgb(255 255 255 / 20%);
    border-radius: 14px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 65px;
  }

  .wave-1 {
    svg {
      height: 2.5rem;
      fill: #1752a3;
      width: 150%;
    }
  }
}








.boxp{
  --border-size: 3px;
  --border-angle: 0turn;
  width: 60vmin;
  height: 50vmin;
  
  background-image: conic-gradient(
    from var(--border-angle),
    #fd7e14,
    #08f 50%,
    #fff
  ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #fd7e14);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}


.boxpa{
  --border-size: 3px;
  --border-angle: 0turn;
  width: 60vmin;
  height: 50vmin;
  
  // background-image: url('../assets/Logo.png') ,
  background-image: conic-gradient(
    from var(--border-angle),
   
    rgb(222, 230, 237) 10%,
    #fff
  ),
     conic-gradient(from var(--border-angle), transparent 20%, #08f, #fd7e14);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}


@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}



@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}
.rainbow {
	position: relative;
	z-index: 0;
	
	border-radius: 10px;
	overflow: hidden;
	// padding: 2rem;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// font-family: sans-serif;
	// font-weight: bold;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#f9f21e, #f9f21e), linear-gradient(#fbb300, #fbb300), linear-gradient(#050505, #000000), linear-gradient(#377af5, #377af5);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
	}
}





// Bables on kuhamisha kituo

.canvas {
  // height: 100vh;
  // width: 100vw;
  background: linear-gradient(to bottom, #edfffa 0%, #31c5d6 100%);
  position: relative;
  overflow: hidden;
}
.bubble {
  display: block;
  border-radius: 100%;
  opacity: 0.8;
  position: absolute;
}
.bubble:nth-child(1) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 28px;
  height: 28px;
  left: 46vw;
  bottom: 47vh;
  -webkit-animation: move1 infinite 10s;
          animation: move1 infinite 10s;
}
@-webkit-keyframes move1 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20vh;
    transform: translate(15px, 0);
    opacity: 0;
  }
}
@keyframes move1 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20vh;
    transform: translate(15px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(2) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 7px;
  height: 7px;
  left: 10vw;
  bottom: 68vh;
  -webkit-animation: move2 infinite 11s;
          animation: move2 infinite 11s;
}
@-webkit-keyframes move2 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 68vh;
    transform: translate(131px, 0);
    opacity: 0;
  }
}
@keyframes move2 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 68vh;
    transform: translate(131px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(3) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 93px;
  height: 93px;
  left: 9vw;
  bottom: 57vh;
  -webkit-animation: move3 infinite 11s;
          animation: move3 infinite 11s;
}
@-webkit-keyframes move3 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 78vh;
    transform: translate(73px, 0);
    opacity: 0;
  }
}
@keyframes move3 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 78vh;
    transform: translate(73px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(4) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 8px;
  height: 8px;
  left: 55vw;
  bottom: 37vh;
  -webkit-animation: move4 infinite 7s;
          animation: move4 infinite 7s;
}
@-webkit-keyframes move4 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 2vh;
    transform: translate(40px, 0);
    opacity: 0;
  }
}
@keyframes move4 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 2vh;
    transform: translate(40px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(5) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 66px;
  height: 66px;
  left: 96vw;
  bottom: 66vh;
  -webkit-animation: move5 infinite 7s;
          animation: move5 infinite 7s;
}
@-webkit-keyframes move5 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 78vh;
    transform: translate(114px, 0);
    opacity: 0;
  }
}
@keyframes move5 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 78vh;
    transform: translate(114px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(6) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 16px;
  height: 16px;
  left: 83vw;
  bottom: 38vh;
  -webkit-animation: move6 infinite 14s;
          animation: move6 infinite 14s;
}
@-webkit-keyframes move6 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 40vh;
    transform: translate(106px, 0);
    opacity: 0;
  }
}
@keyframes move6 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 40vh;
    transform: translate(106px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(7) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 77px;
  height: 77px;
  left: 29vw;
  bottom: 17vh;
  -webkit-animation: move7 infinite 7s;
          animation: move7 infinite 7s;
}
@-webkit-keyframes move7 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 83vh;
    transform: translate(177px, 0);
    opacity: 0;
  }
}
@keyframes move7 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 83vh;
    transform: translate(177px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(8) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 45px;
  height: 45px;
  left: 34vw;
  bottom: 48vh;
  -webkit-animation: move8 infinite 9s;
          animation: move8 infinite 9s;
}
@-webkit-keyframes move8 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 13vh;
    transform: translate(0px, 0);
    opacity: 0;
  }
}
@keyframes move8 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 13vh;
    transform: translate(0px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(9) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 58px;
  height: 58px;
  left: 77vw;
  bottom: 11vh;
  -webkit-animation: move9 infinite 8s;
          animation: move9 infinite 8s;
}
@-webkit-keyframes move9 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 46vh;
    transform: translate(106px, 0);
    opacity: 0;
  }
}
@keyframes move9 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 46vh;
    transform: translate(106px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(10) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 82px;
  height: 82px;
  left: 89vw;
  bottom: 93vh;
  -webkit-animation: move10 infinite 14s;
          animation: move10 infinite 14s;
}
@-webkit-keyframes move10 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 27vh;
    transform: translate(183px, 0);
    opacity: 0;
  }
}
@keyframes move10 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 27vh;
    transform: translate(183px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(11) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 32px;
  height: 32px;
  left: 17vw;
  bottom: 43vh;
  -webkit-animation: move11 infinite 15s;
          animation: move11 infinite 15s;
}
@-webkit-keyframes move11 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 30vh;
    transform: translate(146px, 0);
    opacity: 0;
  }
}
@keyframes move11 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 30vh;
    transform: translate(146px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(12) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 51px;
  height: 51px;
  left: 1vw;
  bottom: 85vh;
  -webkit-animation: move12 infinite 13s;
          animation: move12 infinite 13s;
}
@-webkit-keyframes move12 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 91vh;
    transform: translate(195px, 0);
    opacity: 0;
  }
}
@keyframes move12 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 91vh;
    transform: translate(195px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(13) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 83px;
  height: 83px;
  left: 27vw;
  bottom: 70vh;
  -webkit-animation: move13 infinite 11s;
          animation: move13 infinite 11s;
}
@-webkit-keyframes move13 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 88vh;
    transform: translate(57px, 0);
    opacity: 0;
  }
}
@keyframes move13 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 88vh;
    transform: translate(57px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(14) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 60px;
  height: 60px;
  left: 5vw;
  bottom: 21vh;
  -webkit-animation: move14 infinite 10s;
          animation: move14 infinite 10s;
}
@-webkit-keyframes move14 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 71vh;
    transform: translate(33px, 0);
    opacity: 0;
  }
}
@keyframes move14 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 71vh;
    transform: translate(33px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(15) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 16px;
  height: 16px;
  left: 44vw;
  bottom: 81vh;
  -webkit-animation: move15 infinite 3s;
          animation: move15 infinite 3s;
}
@-webkit-keyframes move15 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20vh;
    transform: translate(183px, 0);
    opacity: 0;
  }
}
@keyframes move15 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20vh;
    transform: translate(183px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(16) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 46px;
  height: 46px;
  left: 89vw;
  bottom: 32vh;
  -webkit-animation: move16 infinite 9s;
          animation: move16 infinite 9s;
}
@-webkit-keyframes move16 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 85vh;
    transform: translate(174px, 0);
    opacity: 0;
  }
}
@keyframes move16 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 85vh;
    transform: translate(174px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(17) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 56px;
  height: 56px;
  left: 21vw;
  bottom: 37vh;
  -webkit-animation: move17 infinite 9s;
          animation: move17 infinite 9s;
}
@-webkit-keyframes move17 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0vh;
    transform: translate(-82px, 0);
    opacity: 0;
  }
}
@keyframes move17 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0vh;
    transform: translate(-82px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(18) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 99px;
  height: 99px;
  left: 29vw;
  bottom: 62vh;
  -webkit-animation: move18 infinite 13s;
          animation: move18 infinite 13s;
}
@-webkit-keyframes move18 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 25vh;
    transform: translate(93px, 0);
    opacity: 0;
  }
}
@keyframes move18 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 25vh;
    transform: translate(93px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(19) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 80px;
  height: 80px;
  left: 56vw;
  bottom: 78vh;
  -webkit-animation: move19 infinite 8s;
          animation: move19 infinite 8s;
}
@-webkit-keyframes move19 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 58vh;
    transform: translate(44px, 0);
    opacity: 0;
  }
}
@keyframes move19 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 58vh;
    transform: translate(44px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(20) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 34px;
  height: 34px;
  left: 6vw;
  bottom: 98vh;
  -webkit-animation: move20 infinite 4s;
          animation: move20 infinite 4s;
}
@-webkit-keyframes move20 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 50vh;
    transform: translate(-12px, 0);
    opacity: 0;
  }
}
@keyframes move20 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 50vh;
    transform: translate(-12px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(21) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 61px;
  height: 61px;
  left: 28vw;
  bottom: 15vh;
  -webkit-animation: move21 infinite 14s;
          animation: move21 infinite 14s;
}
@-webkit-keyframes move21 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 41vh;
    transform: translate(30px, 0);
    opacity: 0;
  }
}
@keyframes move21 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 41vh;
    transform: translate(30px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(22) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 77px;
  height: 77px;
  left: 62vw;
  bottom: 13vh;
  -webkit-animation: move22 infinite 15s;
          animation: move22 infinite 15s;
}
@-webkit-keyframes move22 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 6vh;
    transform: translate(197px, 0);
    opacity: 0;
  }
}
@keyframes move22 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 6vh;
    transform: translate(197px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(23) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 31px;
  height: 31px;
  left: 79vw;
  bottom: 85vh;
  -webkit-animation: move23 infinite 5s;
          animation: move23 infinite 5s;
}
@-webkit-keyframes move23 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 64vh;
    transform: translate(45px, 0);
    opacity: 0;
  }
}
@keyframes move23 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 64vh;
    transform: translate(45px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(24) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 72px;
  height: 72px;
  left: 18vw;
  bottom: 69vh;
  -webkit-animation: move24 infinite 5s;
          animation: move24 infinite 5s;
}
@-webkit-keyframes move24 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 45vh;
    transform: translate(187px, 0);
    opacity: 0;
  }
}
@keyframes move24 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 45vh;
    transform: translate(187px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(25) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 76px;
  height: 76px;
  left: 47vw;
  bottom: 6vh;
  -webkit-animation: move25 infinite 3s;
          animation: move25 infinite 3s;
}
@-webkit-keyframes move25 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 95vh;
    transform: translate(152px, 0);
    opacity: 0;
  }
}
@keyframes move25 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 95vh;
    transform: translate(152px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(26) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 74px;
  height: 74px;
  left: 41vw;
  bottom: 17vh;
  -webkit-animation: move26 infinite 3s;
          animation: move26 infinite 3s;
}
@-webkit-keyframes move26 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 37vh;
    transform: translate(-79px, 0);
    opacity: 0;
  }
}
@keyframes move26 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 37vh;
    transform: translate(-79px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(27) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 22px;
  height: 22px;
  left: 54vw;
  bottom: 15vh;
  -webkit-animation: move27 infinite 13s;
          animation: move27 infinite 13s;
}
@-webkit-keyframes move27 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 48vh;
    transform: translate(121px, 0);
    opacity: 0;
  }
}
@keyframes move27 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 48vh;
    transform: translate(121px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(28) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 62px;
  height: 62px;
  left: 61vw;
  bottom: 78vh;
  -webkit-animation: move28 infinite 12s;
          animation: move28 infinite 12s;
}
@-webkit-keyframes move28 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0vh;
    transform: translate(-56px, 0);
    opacity: 0;
  }
}
@keyframes move28 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0vh;
    transform: translate(-56px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(29) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 82px;
  height: 82px;
  left: 4vw;
  bottom: 10vh;
  -webkit-animation: move29 infinite 8s;
          animation: move29 infinite 8s;
}
@-webkit-keyframes move29 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 36vh;
    transform: translate(88px, 0);
    opacity: 0;
  }
}
@keyframes move29 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 36vh;
    transform: translate(88px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(30) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 31px;
  height: 31px;
  left: 57vw;
  bottom: 26vh;
  -webkit-animation: move30 infinite 7s;
          animation: move30 infinite 7s;
}
@-webkit-keyframes move30 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 73vh;
    transform: translate(80px, 0);
    opacity: 0;
  }
}
@keyframes move30 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 73vh;
    transform: translate(80px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(31) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 56px;
  height: 56px;
  left: 48vw;
  bottom: 78vh;
  -webkit-animation: move31 infinite 6s;
          animation: move31 infinite 6s;
}
@-webkit-keyframes move31 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 51vh;
    transform: translate(167px, 0);
    opacity: 0;
  }
}
@keyframes move31 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 51vh;
    transform: translate(167px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(32) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 71px;
  height: 71px;
  left: 24vw;
  bottom: 52vh;
  -webkit-animation: move32 infinite 12s;
          animation: move32 infinite 12s;
}
@-webkit-keyframes move32 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 5vh;
    transform: translate(32px, 0);
    opacity: 0;
  }
}
@keyframes move32 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 5vh;
    transform: translate(32px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(33) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 51px;
  height: 51px;
  left: 5vw;
  bottom: 37vh;
  -webkit-animation: move33 infinite 14s;
          animation: move33 infinite 14s;
}
@-webkit-keyframes move33 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 19vh;
    transform: translate(182px, 0);
    opacity: 0;
  }
}
@keyframes move33 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 19vh;
    transform: translate(182px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(34) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 32px;
  height: 32px;
  left: 100vw;
  bottom: 57vh;
  -webkit-animation: move34 infinite 3s;
          animation: move34 infinite 3s;
}
@-webkit-keyframes move34 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 50vh;
    transform: translate(184px, 0);
    opacity: 0;
  }
}
@keyframes move34 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 50vh;
    transform: translate(184px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(35) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 90px;
  height: 90px;
  left: 3vw;
  bottom: 40vh;
  -webkit-animation: move35 infinite 7s;
          animation: move35 infinite 7s;
}
@-webkit-keyframes move35 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 53vh;
    transform: translate(102px, 0);
    opacity: 0;
  }
}
@keyframes move35 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 53vh;
    transform: translate(102px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(36) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 40px;
  height: 40px;
  left: 1vw;
  bottom: 44vh;
  -webkit-animation: move36 infinite 14s;
          animation: move36 infinite 14s;
}
@-webkit-keyframes move36 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 99vh;
    transform: translate(-43px, 0);
    opacity: 0;
  }
}
@keyframes move36 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 99vh;
    transform: translate(-43px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(37) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 57px;
  height: 57px;
  left: 91vw;
  bottom: 60vh;
  -webkit-animation: move37 infinite 15s;
          animation: move37 infinite 15s;
}
@-webkit-keyframes move37 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 10vh;
    transform: translate(43px, 0);
    opacity: 0;
  }
}
@keyframes move37 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 10vh;
    transform: translate(43px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(38) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 15px;
  height: 15px;
  left: 37vw;
  bottom: 87vh;
  -webkit-animation: move38 infinite 3s;
          animation: move38 infinite 3s;
}
@-webkit-keyframes move38 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 89vh;
    transform: translate(87px, 0);
    opacity: 0;
  }
}
@keyframes move38 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 89vh;
    transform: translate(87px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(39) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 22px;
  height: 22px;
  left: 99vw;
  bottom: 59vh;
  -webkit-animation: move39 infinite 14s;
          animation: move39 infinite 14s;
}
@-webkit-keyframes move39 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 76vh;
    transform: translate(115px, 0);
    opacity: 0;
  }
}
@keyframes move39 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 76vh;
    transform: translate(115px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(40) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 7px;
  height: 7px;
  left: 22vw;
  bottom: 47vh;
  -webkit-animation: move40 infinite 5s;
          animation: move40 infinite 5s;
}
@-webkit-keyframes move40 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 84vh;
    transform: translate(33px, 0);
    opacity: 0;
  }
}
@keyframes move40 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 84vh;
    transform: translate(33px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(41) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 66px;
  height: 66px;
  left: 92vw;
  bottom: 100vh;
  -webkit-animation: move41 infinite 11s;
          animation: move41 infinite 11s;
}
@-webkit-keyframes move41 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 90vh;
    transform: translate(6px, 0);
    opacity: 0;
  }
}
@keyframes move41 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 90vh;
    transform: translate(6px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(42) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 23px;
  height: 23px;
  left: 61vw;
  bottom: 10vh;
  -webkit-animation: move42 infinite 4s;
          animation: move42 infinite 4s;
}
@-webkit-keyframes move42 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 64vh;
    transform: translate(94px, 0);
    opacity: 0;
  }
}
@keyframes move42 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 64vh;
    transform: translate(94px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(43) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 32px;
  height: 32px;
  left: 48vw;
  bottom: 83vh;
  -webkit-animation: move43 infinite 7s;
          animation: move43 infinite 7s;
}
@-webkit-keyframes move43 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 84vh;
    transform: translate(197px, 0);
    opacity: 0;
  }
}
@keyframes move43 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 84vh;
    transform: translate(197px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(44) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 29px;
  height: 29px;
  left: 48vw;
  bottom: 26vh;
  -webkit-animation: move44 infinite 4s;
          animation: move44 infinite 4s;
}
@-webkit-keyframes move44 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 75vh;
    transform: translate(1px, 0);
    opacity: 0;
  }
}
@keyframes move44 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 75vh;
    transform: translate(1px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(45) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 65px;
  height: 65px;
  left: 25vw;
  bottom: 11vh;
  -webkit-animation: move45 infinite 10s;
          animation: move45 infinite 10s;
}
@-webkit-keyframes move45 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 10vh;
    transform: translate(89px, 0);
    opacity: 0;
  }
}
@keyframes move45 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 10vh;
    transform: translate(89px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(46) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 82px;
  height: 82px;
  left: 26vw;
  bottom: 47vh;
  -webkit-animation: move46 infinite 14s;
          animation: move46 infinite 14s;
}
@-webkit-keyframes move46 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 69vh;
    transform: translate(-30px, 0);
    opacity: 0;
  }
}
@keyframes move46 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 69vh;
    transform: translate(-30px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(47) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 96px;
  height: 96px;
  left: 43vw;
  bottom: 16vh;
  -webkit-animation: move47 infinite 9s;
          animation: move47 infinite 9s;
}
@-webkit-keyframes move47 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 70vh;
    transform: translate(65px, 0);
    opacity: 0;
  }
}
@keyframes move47 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 70vh;
    transform: translate(65px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(48) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 56px;
  height: 56px;
  left: 74vw;
  bottom: 80vh;
  -webkit-animation: move48 infinite 15s;
          animation: move48 infinite 15s;
}
@-webkit-keyframes move48 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 54vh;
    transform: translate(-33px, 0);
    opacity: 0;
  }
}
@keyframes move48 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 54vh;
    transform: translate(-33px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(49) {
  background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 88px;
  height: 88px;
  left: 2vw;
  bottom: 23vh;
  -webkit-animation: move49 infinite 8s;
          animation: move49 infinite 8s;
}
@-webkit-keyframes move49 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 51vh;
    transform: translate(-74px, 0);
    opacity: 0;
  }
}
@keyframes move49 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 51vh;
    transform: translate(-74px, 0);
    opacity: 0;
  }
}
.bubble:nth-child(50) {
  background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
  width: 24px;
  height: 24px;
  left: 44vw;
  bottom: 74vh;
  -webkit-animation: move50 infinite 8s;
          animation: move50 infinite 8s;
}
@-webkit-keyframes move50 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 71vh;
    transform: translate(-38px, 0);
    opacity: 0;
  }
}
@keyframes move50 {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 71vh;
    transform: translate(-38px, 0);
    opacity: 0;
  }
}

/* CSS */
.form-control-label::placeholder {
  color: #999; /* Adjust the color to your preference */
}

/* Optional: Change the placeholder text color on focus */
.form-control-label:focus::placeholder {
  color: #555; /* Adjust the color to your preference */
}