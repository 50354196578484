
.path-33 {
    /* fill:#6d3925; */
        fill: rgb(253, 126, 20);
            
}
.nec-footer {
    font-size: 14px;
    /* background:  #6d3925; */
     background: rgb(253, 126, 20);;
    color: #fff;
}
.nec-footer a {
    font-size: 14px;
    color: #fff;
}




.border-box{
    background: none;
}
footer{
    border-top-style: none;
    padding: 0;
}
.footer-copyright{
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #ffffff;
    /* background:  #6d3925; */
    background: rgb(253, 126, 20);
            
    color: #ffffff;
}