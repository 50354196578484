/* CSS Document */

/*
	Theme Name: NEC Theme
	Author: Rodgers Akwilini
*/


@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic);





/*------------------------------------------*/
/*	     01 - General & Basic Styles
/*------------------------------------------*/
body {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
    line-height: 21px;
	font-weight: 400;
	color: #333;

}
strong, b {
	font-weight: 600;
}

img {
	max-width: 100%;
	height: auto;
}

ul, ol {
	list-style: none;
}

ul {
    padding: 0;
    margin: 0;
}





.navbar {
	margin-top: 0;
	margin-bottom: 0;
	background: #fff;
	border: none;
	border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
}










/*------------------------------------------*/
/*			 11 - Footer Styles
/*------------------------------------------*/

footer {
	background-color: #222;
    padding-top: 18px;
	border-top: 4px solid #555;
	color: #ccc;
}

footer p {
	color: #ccc;
}

footer a {
	color: #aaa;
}

footer a:hover {
	color: #aaa;
	text-decoration: underline;
}

.footer-widget {
    margin-bottom: 1px;
}

.footer-widget h4 {
	color: #eee;
	text-transform: uppercase;
	padding-bottom: 8px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 700;
	position: relative;
}

.footer-widget h4 .head-line {
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	width: 50px;
	height: 3px;
	background-color: #333;
	margin: 0;
}

.social-widget ul.social-icons li {
	display: inline-block;
	margin-right: 4px;
	margin-bottom: 4px;
}

.title{
	line-height: 1.3;
	font-size: larger;
}










.gov_logo {
	/*border: solid 1px red;*/
	overflow: hidden;
	height: 120px;
	width: 120px;
	float: left;
	margin-left:100px;
	padding: 0px !important;
}

.nec_logo {
	/*border: solid 1px red;*/
	overflow: hidden;
	height: 120px;
    padding-right: 10px;
	width: 120px;
	float: right;
}



// mstari wa chini ya header pale

.nav_tr {
	border-bottom: solid 1px #ee3733 !important;
}

.navbar-brand {
	padding: 0px !important;
}

// /*.tr_slider {
// 	overflow: hidden;
// 	margin-top: 10px;
// 	border: solid 1px blue;
// }*/

// /*ul.tr_menu li>a {
// 	padding-top: 0px !important;
// 	padding-bottom: 0px !important;
// }*/



.profile {
    /* width: 16.25%; */
    position: relative;
    /*border: solid 1px blue;*/
	padding-right:0px;
	text-align: center;
    float: left;
    margin-top: 7px;
    /*padding: 7px 0px;*/
}




.tr_pro {
	padding-right: 0px;
	padding-left: 0px;
}

ul.tr_menu li.tr_mfix>a {
	padding-top: 9px !important;
	padding-bottom: 9px !important;
}

.pad_remover {
	padding-right: 0px !important;
	padding-left:  0px !important;
}

.pad {
	padding-top: 1px;
	padding-bottom: 1px;
}

.top_tr {
	border: solid 1px #eee !important;
	 max-width: 1140px;
	padding-left: 0px;
	padding-right: 0px;
	-webkit-box-shadow: 0px 0px 6px -3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 00px 0px 6px -3px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 6px -3px rgba(0, 0, 0, 0.6);
}

ul.link li a {
	color: #000 !important;
}

ul.link li a:hover{
	color:#ee3733 !important;
}

ul.tr_top li {
	/*border: solid 1px red;*/
	margin:5px 3px 3px 3px;
}

ul.tr_top li a:hover {
	color: #000;
	text-decoration: underline;
}

.tr_slog {
	/*border: solid 1px red;*/
	width: 100%;
	float: left;
	text-align: center;
}

p#body {
	font-family: 'Open Sans', sans-serif;
	font-size: 32px;
    color: #cb4939;
    font-weight: bold;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
}

p#head {
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	margin: 17px 5px 13px 5px;
	font-weight: bold;
	color: #333;
}

.top-ts{
	margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    box-shadow: 1px 2px 10px rgba(50, 50, 50, 0.75);
}

body {
	background-color: #fff;
	background: none;
}

/*Boostrap changes*/
.container > .navbar-header{
    margin-right: 0;
    margin-left: 0;
    // background-color: #fbeedc;
}
.top-row {
    background-color: #f1505b;
    color: #ffffff;
}

.top-bar a {
    color: #ffffff;
}


.view-all {
    float: right;
    font-weight: 600;
    margin-top: -26px;
}
.nav-container{
	background: #edeef0;
	margin-bottom: 10px;
	box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 1px 3px 1px;
	z-index: 10;
}
.bg-light{
	border-color: #edeef0;
	z-index: 10;
	margin-bottom: 10px;
	box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 1px 3px 1px;
	border-bottom-style: inset;
}
